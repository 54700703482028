// Generated by Framer (e75c18f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["KmTqKekuY", "vX6yKmhiD"];

const serializationHash = "framer-lmX1Z"

const variantClassNames = {KmTqKekuY: "framer-v-1rkudib", vX6yKmhiD: "framer-v-1eh40u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Default: "KmTqKekuY", Small: "vX6yKmhiD"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, dOaEfcsWl: image ?? props.dOaEfcsWl ?? {src: "https://framerusercontent.com/images/y6plblDx61WOkD8v1YL3Z3oqw.svg"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "KmTqKekuY"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dOaEfcsWl, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "KmTqKekuY", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Image {...restProps} animate={variants} background={{alt: "", fit: "fill", intrinsicHeight: 80, intrinsicWidth: 80, pixelHeight: 80, pixelWidth: 80, sizes: "min(96px, 100vw)", ...toResponsiveImage(dOaEfcsWl)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1rkudib", className, classNames)} data-framer-name={"Default"} initial={variant} layoutDependency={layoutDependency} layoutId={"KmTqKekuY"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", ...style}} {...addPropertyOverrides({vX6yKmhiD: {"data-framer-name": "Small", background: {alt: "", fit: "fill", intrinsicHeight: 80, intrinsicWidth: 80, pixelHeight: 80, pixelWidth: 80, sizes: "min(40px, 100vw)", ...toResponsiveImage(dOaEfcsWl)}}}, baseVariant, gestureVariant)}/></Transition>
</LayoutGroup>)

});

const css = [".framer-lmX1Z[data-border=\"true\"]::after, .framer-lmX1Z [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lmX1Z.framer-1nlczre, .framer-lmX1Z .framer-1nlczre { display: block; }", ".framer-lmX1Z.framer-1rkudib { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 96px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 96px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lmX1Z.framer-1rkudib { gap: 0px; } .framer-lmX1Z.framer-1rkudib > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-lmX1Z.framer-1rkudib > :first-child { margin-left: 0px; } .framer-lmX1Z.framer-1rkudib > :last-child { margin-right: 0px; } }", ".framer-lmX1Z.framer-v-1eh40u.framer-1rkudib { height: 40px; width: 40px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 96
 * @framerIntrinsicWidth 96
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"vX6yKmhiD":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"dOaEfcsWl":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerJSb5hxUWK: React.ComponentType<Props> = withCSS(Component, css, "framer-lmX1Z") as typeof Component;
export default FramerJSb5hxUWK;

FramerJSb5hxUWK.displayName = "Circle image";

FramerJSb5hxUWK.defaultProps = {height: 96, width: 96};

addPropertyControls(FramerJSb5hxUWK, {variant: {options: ["KmTqKekuY", "vX6yKmhiD"], optionTitles: ["Default", "Small"], title: "Variant", type: ControlType.Enum}, dOaEfcsWl: {__defaultAssetReference: "data:framer/asset-reference,y6plblDx61WOkD8v1YL3Z3oqw.svg?originalFilename=Clip1.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerJSb5hxUWK, [])